import logo_1 from '../../assets/sterneschild.png'
import logo_2 from '../../assets/weinsueden-hotel.png'
import logo_3 from '../../assets/weinsueden-vinothek.png'
import logo_4 from '../../assets/bettBike.png'
import SectionLink from './SectionLink'

function LogoPart() {
    return (
        <div className='logo_part_wrapper'>
            <SectionLink
                link="https://www.hotelstars.eu"
                linkItem={<img src={logo_1} alt="hotel_stars" className="footer_logo" />}
                isImageLink={true}
            />
            <SectionLink
                link="https://www.tourismus-bw.de/urlaub-im-sueden/genuss/weinsueden/weinhotels"
                linkItem={<img src={logo_2} alt="wein_süden" className="footer_logo" />}
                isImageLink={true}
            />
            <SectionLink
                link="https://schloss-hotel-ingelfingen.de/weine/#"
                linkItem={<img src={logo_3} alt="wein_süden" className="footer_logo" />}
                isImageLink={true}
            />
            <SectionLink
                link="https://www.bettundbike.de"
                linkItem={<img src={logo_4} alt="bett+bike" className="footer_logo" />}
                isImageLink={true}
            />
        </div>
    )
}

export default LogoPart
