import { IAttribute } from '../../models/IOutputData'
import KeyValue from '../keyValue/KeyValue'

interface ProductAttributeDetailsProps {
    productAttributes: IAttribute[]
}

function ProductAttributeDetails({ productAttributes }: ProductAttributeDetailsProps) {
    return (
        <KeyValue attributes={productAttributes} />
    )
}

export default ProductAttributeDetails
