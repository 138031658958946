import './SectionTitle.scss'

interface SectionTitleProps {
    title: string
}

function SectionTitle({ title }: SectionTitleProps) {
    return (
        <div className='section_title'>
            {title}
        </div>
    )
}

export default SectionTitle
