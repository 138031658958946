import manufacturerIcon from '../../../assets/manufacturerIcon.jpg'
import shadow from '../../../assets/shadowImg.png'
import KeyValue from '../../keyValue/KeyValue'
import LanguageSelector from '../../languageSelector/LanguageSelector'
import Layout from '../../layout/Layout'


interface GeneralMobileProps {
    icon: string,
    attributes: any,
    title: string,
    language: string,
    setLanguage: (lang: string) => void
}

function GeneralMobile({ icon, attributes, title, language, setLanguage }: GeneralMobileProps) {
    return (
        <div data-testid='general_mobile_view'>
            <div className="manufacturer_icon_wrapper">
                <img
                    id='manufacturer_icon'
                    className="manufacturer_icon"
                    src={manufacturerIcon} alt="manufacturer_icon" />
                <div className="language_wrapper">
                    <LanguageSelector
                        setSelectedLanguage={setLanguage}
                        selectedLanguage={language}
                    />
                </div>
            </div>
            <div className="product_title">
                {title}
            </div>

            <div className='icon_wrapper' data-testid='product_icon'>
                <img className='icon_1' src={icon} alt="product_icon" />
                <img className='icon_2' src={shadow} alt="product_icon_shadow" />
            </div>

            <Layout
                children_1={''}
                children_2={<KeyValue attributes={attributes} />}
            />
        </div>
    )
}

export default GeneralMobile
