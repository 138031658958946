import { CertificateTexts } from "../../language/Texts"

function CertificateInfo() {
    return (
        <div
            className='certificate_info_wrapper'
            data-testid='certificate_info'
        >
            <div className="certificate_title">{CertificateTexts.certificateTitle}</div>
            <div className="certificate_text">{CertificateTexts.certificateText_1}</div>
            <div className="certificate_text">{CertificateTexts.certificateText_2}</div>
            <div className="certificate_text">{CertificateTexts.certificateText_3}</div>
            <div className="certificate_text">{CertificateTexts.certificateText_4}</div>
        </div>
    )
}

export default CertificateInfo
