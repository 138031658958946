import { MdKeyboardControlKey } from "react-icons/md"
import './Footer.scss'
import HotelLogo from './HotelLogo'
import Legal from './Legal'
import LogoPart from './LogoPart'
import OpeningHours from './OpeningHours'
import Service from './Service'

function Footer() {


    const scrollToTop = () => {
        // Find the main container element by its class or ID
        const manufacturerIconElement = document.getElementById('manufacturer_icon');
        if (manufacturerIconElement) {
            manufacturerIconElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='footer_wrapper_1'>
            <div className='footer_wrapper_2'>
                <div className="footer_info_part">
                    <HotelLogo />
                    <Legal />
                    <Service />
                    <OpeningHours />
                </div>
                <LogoPart />
                <div
                    className="up_icon_wrapper"
                >
                    <MdKeyboardControlKey
                        onClick={scrollToTop}
                        className='up_icon' />
                </div>
            </div>
        </div>
    )
}

export default Footer
