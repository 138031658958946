import { useState } from "react";
import { Button } from "react-bootstrap";
import { OtherTexts } from "../../language/Texts";
import { HttpRequests } from "../../utils/HttpRequests";
import "./Rating.scss";
import RatingStars from "./RatingStars";

const RatingDetails = ({ language, materialNumber }) => {
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [isRated, setIsRated] = useState<boolean>(false);

    const handleClick = async () => {
        // After submit user sees always thanks messagge,regardless of request status 
        setIsRated(true);
        try {
            await new HttpRequests().ratingComponent(language, rating, materialNumber)
        } catch (error) {
            console.error('error on rating component', error);
        }
    }

    return (
        <div className="evaluation_wrapper">
            {
                !isRated ?
                    <>
                        <RatingStars
                            rating={rating} setRating={setRating}
                            hover={hover} setHover={setHover} />
                        <Button
                            disabled={rating === 0}
                            onClick={handleClick}
                            className="info_value_link evaluation_button">
                            {OtherTexts.sendEvalutation}</Button>
                    </> :
                    <div className="evaluation_thanks_text">{OtherTexts.thanksForRating}</div>
            }
        </div>
    );
};

export default RatingDetails;
