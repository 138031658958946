import { IAttribute } from '../../models/IOutputData'
import Layout from '../layout/Layout'
import './CertificateComponent.scss'
import CertificateIcon from './CertificateIcon'
import CertificateInfo from './CertificateInfo'

interface CertificateComponentProps {
    productAttributesListPrioZero: IAttribute[]
}

function CertificateComponent({ productAttributesListPrioZero }: CertificateComponentProps) {
    const shouldCertificateBeDisplayed = () => {
        return productAttributesListPrioZero.some(element =>
            element.title.toLowerCase().includes("bio")
        );
    };

    return (
        <>{
            shouldCertificateBeDisplayed() &&
            <Layout
                children_1={<CertificateIcon />}
                children_2={<CertificateInfo />}
            />
        }
        </>



    )
}

export default CertificateComponent
