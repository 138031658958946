import axios from 'axios';
import IResponse from '../models/IResponse';
import version from '../version.json'

export class HttpRequests {

    async requestComponent(lang?: string): Promise<IResponse | null> {
        const requestURL = this.getRequestUrl('getcomponent')
        const requestData = this.getRequestData(lang)

        return await axios.post(requestURL, requestData)
            .then(result => this.sendResponseWithVersion(result))
            .catch(error => {
                console.error('Error on getting component', error);
                throw error
            })
    }

    async ratingComponent(lang: string, rating: number, materialNumber: string) {
        const requestURL = this.getRequestUrl('ratecomponent')
        const requestData = this.getRequestData(materialNumber, lang, rating,)

        return await axios.post(requestURL, requestData)
            .then(result => result)
            .catch(error => {
                throw error
            })
    }

    private getRequestUrl(path: string): string {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return `http://localhost:8080/api/${path}`
        } else {
            return `/api/${path}`
        }
    }

    private sendResponseWithVersion(result) {
        const version = result.headers.get("Webview-Backend-Version") ?? null
        const responseData = result.data
        if (version) {
            responseData.backendVersion = version
        }
        return responseData
    }

    private getRequestData = (materialNumber?: string, lang?: string, rating?: number) => {
        const requestData = {
            url: window.location.href,
            language: lang,
            type: "schlosshotel",
            additional: {
                frontendVersion: version.version,
                displayResolution: `${window.screen.width}x${window.screen.height}`,
            }
        }
        if (rating) {
            requestData.additional['rating'] = rating
        }
        if (materialNumber) {
            requestData.additional['materialNumber'] = materialNumber
        }
        return requestData
    }
}