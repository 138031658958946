interface SectionTitleProps {
    title: string
}

function SectionTitle({ title }: SectionTitleProps) {
    return (
        <div className="footer_section_title">{title}</div>
    )
}

export default SectionTitle
