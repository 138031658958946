import CertificateComponent from '../../components/certificateComponent/CertificateComponent';
import ConexoLogo from '../../components/conexoLogo/ConexoLogo';
import Documents from '../../components/documents/Documents';
import Footer from '../../components/footer/Footer';
import General from '../../components/general/General';
import MediaGallery from '../../components/mediaGallery/MediaGallery';
import ProductAttributes from '../../components/productAttributes/ProductAttributes';
import Evaluation from '../../components/rating/Rating';
import { IOutputData } from '../../models/IOutputData';
import { Utils } from '../../utils/Utils';
import './Main.scss';

interface MainProps {
    component: IOutputData,
    language: string,
    setLanguage: (lang: string) => void
}

function Main({ component, language, setLanguage }: MainProps) {
    const documentList = component.productDocuments.concat(component.componentDocuments)

    // on safari there is a bug. if user scrolls up
    // there is a white space at the bottom of the screen
    // that is why we need to add special style
    const getClassName = (className: string) => {
        if (Utils.isIosDevice()) {
            return `ios_${className}`
        } else {
            return className
        }
    }

    return (
        <div className={getClassName('main_wrapper_1')} data-testid='main'>
            <div className='main_wrapper_2'>
                <div className='main_wrapper_3'>
                    <General
                        language={language}
                        setLanguage={setLanguage}
                        component={component} />
                    <ProductAttributes productAttributes={component.productAttributesList} />
                    <Documents documentList={documentList} />
                    <Evaluation language={language} materialNumber={component.generalAttributesList?.componentType} />
                    <CertificateComponent productAttributesListPrioZero={component.productAttributesListPrioZero} />
                    <MediaGallery />
                    <ConexoLogo />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Main
