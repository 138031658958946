import { Button } from 'react-bootstrap'
import { IAttribute } from '../../models/IOutputData'
import { Utils } from '../../utils/Utils'
import './KeyValue.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { OtherTexts } from '../../language/Texts'


interface KeyValueProps {
    attributes: IAttribute[]
}

function KeyValue({ attributes }: KeyValueProps) {
    return (
        <>
            {attributes && attributes.length > 0 &&
                attributes?.map((attribute, index) => {
                    return (
                        <div key={index} className="info_pair">
                            <div className="info_key" data-testid='info_key'>
                                {attribute.title}
                            </div>
                            <div className="info_value" data-testid='info_value'>
                                {Utils.isValidUrl(attribute.value) ?
                                    <Button
                                        className="info_value_link"
                                        type="button"
                                        onClick={() => window.open(attribute.value, "_blank")}>
                                        <FontAwesomeIcon
                                            className='info_value_link_icon'
                                            icon={faExternalLinkAlt} />  {OtherTexts.openLink}
                                    </Button> :

                                    attribute.value}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default KeyValue
