import { useEffect, useState } from 'react';
import './App.scss';
import i18n from './language/i18n';
import translation from './language/translation.json';
import IResponse from './models/IResponse';
import Error from './pages/Error/Error';
import Loader from './pages/Loader/Loader';
import Main from './pages/Main/Main';
import NotFound from './pages/NotFound/NotFound';
import { HttpRequests } from './utils/HttpRequests';

function App() {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState<IResponse | null>(null)
  const [language, setLanguage] = useState(translation['language'][i18n.language]);

  useEffect(() => {
    getComponent()
  }, [language])

  const getComponent = async () => {
    setLoading(true)
    try {
      const result = await new HttpRequests().requestComponent(i18n.language)
      document.title = result?.component?.articleDescriptionS || "WEINMANUFAKTUR"
      setLoading(false)
      setResponse(result as any)
    } catch (error) {
      setLoading(false)
      setResponse(null)
    }
  }

  const getLayout = () => {
    if (loading) {
      return <Loader loading={loading} />
    }
    else if (response?.error === null && response?.component !== null) {
      return <Main
        setLanguage={setLanguage}
        language={language}
        component={response.component} />
    }
    else if (response?.error === "NOT_FOUND" && response?.component === null) {
      return <NotFound />
    }
    else {
      return <Error />
    }
  }

  return (
    <div className='app'>
      {getLayout()}
    </div>
  );
}

export default App;
