import LoaderAnimation from 'react-spinners/BeatLoader';
import './Loader.scss'

interface LoaderProps {
    loading: boolean
}

function Loader({ loading }: LoaderProps) {
    return (
        <div className="loader-wrapper" data-testid="loader">
            <LoaderAnimation color={"#ac8b51"} loading={loading} size={50} />
        </div>
    )
}

export default Loader
