import shadow from '../../assets/shadowImg.png'

interface ProductImageProps {
    icon: string
}

function IconComponent({ icon }: ProductImageProps) {
    return (
        <div className='icon_wrapper' data-testid='product_icon'>
            <img className='icon_1' src={icon} alt="product_icon" />
            <img className='icon_2' src={shadow} alt="product_icon_shadow" />
        </div>
    )
}

export default IconComponent
