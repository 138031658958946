import { FooterTexts } from "../../language/Texts"

function OpeningHours() {
    return (
        <div className='section_wrapper'>
            <div className="footer_section_title">{FooterTexts.openingHours}</div>
            <div className="sub_section_wrapper">
                <div className="sub_section">{FooterTexts.receptionHour}</div>
                <div className="sub_section">{FooterTexts.checkinHour}</div>
                <div className="sub_section">{FooterTexts.checkoutHour}</div>
            </div>
            <div className="sub_section_wrapper">
                <div className="sub_section">{FooterTexts.kitchenHour}</div>
                <div className="sub_section">{FooterTexts.kitchenHour1}</div>
                <div className="sub_section">{FooterTexts.kitchenHour2}</div>
                <div className="sub_section">{FooterTexts.kitchenHour3}</div>
                <div className="sub_section">{FooterTexts.kitchenHour4}</div>
                <div className="sub_section">{FooterTexts.kitchenHour5}</div>
            </div>
            <div className="sub_section_wrapper">
                <div className="sub_section">{FooterTexts.breakfastTime}</div>
                <div className="sub_section">{FooterTexts.breakfastTime1}</div>
                <div className="sub_section">{FooterTexts.breakfastTime2}</div>
            </div>

        </div>
    )
}

export default OpeningHours
