import { SectionTexts } from '../../language/Texts'
import SectionTitle from '../sectionTitle/SectionTitle'
import './MediaGallery.scss'
import MediaGalleryDetails from './MediaGalleryDetails'

function MediaGallery() {
    return (
        <div>
            <SectionTitle title={SectionTexts.mediaGalleryTitle} />
            <MediaGalleryDetails />
        </div>
    )
}

export default MediaGallery
