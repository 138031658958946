interface SectionLinkProps {
    link: string,
    linkItem: string | JSX.Element
    isImageLink?: boolean
}

function SectionLink({ link, linkItem, isImageLink }: SectionLinkProps) {
    return (
        <>
            {isImageLink ?
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={link}>
                    {linkItem}
                </a> :
                <div className="section">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={link}>
                        {linkItem}
                    </a>
                </div>}
        </>
    )
}

export default SectionLink
