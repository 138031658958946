import { IDocument } from '../../models/IDocument'
import Layout from '../layout/Layout'
import SectionTitle from '../sectionTitle/SectionTitle'
import DocumentsDetails from './DocumentsDetails'
import './Documents.scss'
import { SectionTexts } from '../../language/Texts'

interface DocumentProps {
    documentList: IDocument[]
}

function Documents({ documentList }: DocumentProps) {
    return (
        <Layout
            children_1={<SectionTitle title={SectionTexts.documents} />}
            children_2={<DocumentsDetails documentList={documentList} />}
        />
    )
}

export default Documents
