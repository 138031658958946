import legal_1 from '../../assets/allianz.png'
import legal_2 from '../../assets/klimaneutrale.png'
import { FooterTexts } from '../../language/Texts'
import SectionLink from './SectionLink'
import SectionTitle from './SectionTitle'

function Legal() {
    return (
        <div className='section_wrapper'>
            <SectionTitle title={FooterTexts.legal} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/agb/" linkItem={FooterTexts.conditions} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/impressum/" linkItem={FooterTexts.imprint} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/datenschutz/" linkItem={FooterTexts.dataProtection} />
            <SectionLink link="https://www.gemu-group.com/de_DE/" linkItem={FooterTexts.gemuGroup} />
            <SectionLink
                link="https://allianz-entwicklung-klima.de"
                linkItem={<img src={legal_1} alt="allianz_logo" className="legal_logo" />}
                isImageLink={true}
            />
            <SectionLink
                link="https://www.gemu-group.com/de_DE/gemue-gruppe/nachhaltigkeit/"
                linkItem={<img src={legal_2} alt="sustainability_logo" className="legal_logo" />}
                isImageLink={true}
            />
        </div>
    )
}

export default Legal
