import i18n from './i18n';

export class CertificateTexts {
    constructor() { }
    static get certificateTitle() { return i18n.t(`certificateTitle.${i18n.language}`) }
    static get certificateText_1() { return i18n.t(`certificateText_1.${i18n.language}`) }
    static get certificateText_2() { return i18n.t(`certificateText_2.${i18n.language}`) }
    static get certificateText_3() { return i18n.t(`certificateText_3.${i18n.language}`) }
    static get certificateText_4() { return i18n.t(`certificateText_4.${i18n.language}`) }
}

export class SectionTexts {
    constructor() { }
    static get documents() { return i18n.t(`documents.${i18n.language}`) }
    static get evaluation() { return i18n.t(`evaluation.${i18n.language}`) }
    static get details() { return i18n.t(`details.${i18n.language}`) }
    static get mediaGalleryTitle() { return i18n.t(`mediaGalleryTitle.${i18n.language}`) }
}

export class FooterTexts {
    constructor() { }
    static get conditions() { return i18n.t(`conditions.${i18n.language}`) }
    static get imprint() { return i18n.t(`imprint.${i18n.language}`) }
    static get dataProtection() { return i18n.t(`dataProtection.${i18n.language}`) }
    static get gemuGroup() { return i18n.t(`gemuGroup.${i18n.language}`) }
    static get contact() { return i18n.t(`contact.${i18n.language}`) }
    static get career() { return i18n.t(`career.${i18n.language}`) }
    static get downloadBrochure() { return i18n.t(`downloadBrochure.${i18n.language}`) }
    static get service() { return i18n.t(`service.${i18n.language}`) }
    static get legal() { return i18n.t(`legal.${i18n.language}`) }
    static get openingHours() { return i18n.t(`openingHours.${i18n.language}`) }
    static get receptionHour() { return i18n.t(`receptionHour.${i18n.language}`) }
    static get checkinHour() { return i18n.t(`checkinHour.${i18n.language}`) }
    static get checkoutHour() { return i18n.t(`checkoutHour.${i18n.language}`) }
    static get kitchenHour() { return i18n.t(`kitchenHour.${i18n.language}`) }
    static get kitchenHour1() { return i18n.t(`kitchenHour1.${i18n.language}`) }
    static get kitchenHour2() { return i18n.t(`kitchenHour2.${i18n.language}`) }
    static get kitchenHour3() { return i18n.t(`kitchenHour3.${i18n.language}`) }
    static get kitchenHour4() { return i18n.t(`kitchenHour4.${i18n.language}`) }
    static get kitchenHour5() { return i18n.t(`kitchenHour5.${i18n.language}`) }
    static get breakfastTime() { return i18n.t(`breakfastTime.${i18n.language}`) }
    static get breakfastTime1() { return i18n.t(`breakfastTime1.${i18n.language}`) }
    static get breakfastTime2() { return i18n.t(`breakfastTime2.${i18n.language}`) }
}


export class OtherTexts {
    constructor() { }
    static get openLink() { return i18n.t(`openLink.${i18n.language}`) }
    static get sendEvalutation() { return i18n.t(`sendEvalutation.${i18n.language}`) }
    static get thanksForRating() { return i18n.t(`thanksForRating.${i18n.language}`) }
}
