import { SectionTexts } from '../../language/Texts'
import Layout from '../layout/Layout'
import SectionTitle from '../sectionTitle/SectionTitle'
import RatingDetails from './RatingDetails'

function Rating({language, materialNumber}) {
  return (
    <Layout
      children_1={<SectionTitle title={SectionTexts.evaluation} />}
      children_2={<RatingDetails language={language} materialNumber={materialNumber}/>}
    />
  )
}

export default Rating