import { FaRegStar, FaStar } from "react-icons/fa";

interface RatingStarsProps {
    rating: number
    setRating: (rating: number) => void
    hover: number
    setHover: (hover: number) => void
}

function RatingStars({ rating, setRating, hover, setHover }: RatingStarsProps) {
    return (
        <div>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                    <span key={i} onClick={() => setRating(ratingValue)}>
                        {ratingValue <= (hover || rating) ? (
                            <FaStar
                                className="star"
                                color="#ac8b51"
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(0)}
                                data-testid={`star-${ratingValue}`}
                            />
                        ) : (
                            <FaRegStar
                                className="star"
                                color="#ac8b51"
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(0)}
                                data-testid={`star-${ratingValue}`}
                            />
                        )}
                    </span>
                );
            })}
        </div>
    )
}

export default RatingStars