import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDocument } from '../../models/IDocument';
import { Utils } from '../../utils/Utils';

interface DocumentProps {
    documentList: IDocument[]
}

function DocumentsDetails({ documentList }: DocumentProps) {

    return (
        <>
            <div className="document_list">
                {documentList?.map((document, index) => {
                    return (
                        <div key={index} className="document_item_wrapper">
                            <div className='document_title_part'>
                                <FontAwesomeIcon className="file_icon" icon={faFile} />
                                <a
                                    className="doc_link"
                                    href={document.link}
                                    rel="noreferrer"
                                    type={document.mimeType}
                                    target="_blank"
                                    data-testid="document_link"
                                >
                                    {Utils.removePrefixAndSuffix(document.title)}
                                </a>
                            </div>
                            <FontAwesomeIcon
                                data-testid="download_icon"
                                onClick={() => window.open(document.link, "_blank")}
                                className="download_icon"
                                icon={faDownload} />
                        </div>
                    )
                })
                }
            </div>
        </>
    )
}

export default DocumentsDetails
