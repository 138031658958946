import translation from '../language/translation.json'

export class Utils {

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static isLanguageSupported(lang: string): boolean {
        return translation.language[lang]
    }

    static isValidUrl(urlString: string): boolean {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    static removePrefixAndSuffix(text: string): string {
        let fileName = text.split(".")[0];
        const fileType = text.split(".")[1];

        // remove prefix
        fileName = fileName.replace(/INEVVO2018_/g, '');
        // remove suffix
        fileName = fileName.replace(/-([A-Z0-9]+)-([A-Z0-9]+)$/g, '');

        return fileName + "." + fileType;
    }

    static isIosDevice(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent)
    }
}