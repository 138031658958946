import { useEffect, useState } from 'react'
import { IOutputData } from '../../models/IOutputData'
import Layout from '../layout/Layout'
import './General.scss'
import GeneralDetails from './GeneralDetails'
import IconComponent from './IconComponent'
import GeneralMobile from './mobile/GeneralMobile'

interface GeneralProps {
    component: IOutputData
    language: string
    setLanguage: (lang: string) => void
}

function General({ component, language, setLanguage }: GeneralProps) {
    const [windowWidth, setWindowWidth] = useState(0);
    const isMobile = window.innerWidth < 769;

    const sortedAttributes = component?.manufacturerAttributesList?.sort((a, b) => a.prioInOverview - b.prioInOverview)
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (<>
        {isMobile ?
            <GeneralMobile
                title={component.articleDescriptionS}
                attributes={sortedAttributes}
                icon={component.icon}
                language={language}
                setLanguage={setLanguage}
            />
            :
            <Layout
                children_1={<IconComponent icon={component.icon} />}
                children_2={<GeneralDetails
                    title={component.articleDescriptionS}
                    trustedAttributes={sortedAttributes}
                    language={language}
                    setLanguage={setLanguage}
                />}
            />
        }
    </>
    )
}

export default General
