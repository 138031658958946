import { FooterTexts } from '../../language/Texts'
import SectionLink from './SectionLink'
import SectionTitle from './SectionTitle'

function Service() {
    return (
        <div className='section_wrapper'>
            <SectionTitle title={FooterTexts.service} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/kontakt/" linkItem={FooterTexts.contact} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/kontakt/" linkItem={FooterTexts.career} />
            <SectionLink link="https://schloss-hotel-ingelfingen.de/wp-content/uploads/2019/05/broschure-web.pdf" linkItem={FooterTexts.downloadBrochure} />
        </div>
    )
}

export default Service
