import { SectionTexts } from '../../language/Texts'
import { IAttribute } from '../../models/IOutputData'
import Layout from '../layout/Layout'
import SectionTitle from '../sectionTitle/SectionTitle'
import ProductAttributeDetails from './ProductAttributeDetails'

interface ProductAttributesProps {
    productAttributes: IAttribute[]
}

function ProductAttributes({ productAttributes }: ProductAttributesProps) {
    return (
        <Layout
            children_1={<SectionTitle title={SectionTexts.details} />}
            children_2={<ProductAttributeDetails productAttributes={productAttributes} />}
        />
    )
}

export default ProductAttributes
