import certificateIcon from '../../assets/euBio.jpg'

function CertificateIcon() {
    return (
        <div>
            <img
                className='certificate_icon'
                src={certificateIcon}
                alt="certificate"
                data-testid='certificate_icon'
            />
        </div>
    )
}

export default CertificateIcon
