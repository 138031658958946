import Carousel from 'react-bootstrap/Carousel';
import image1 from "../../assets/grape-1.jpg";
import image2 from "../../assets/grape-2.jpg";
import image3 from "../../assets/grape-3.jpg";
import image4 from "../../assets/grape-4.jpg";

function MediaGalleryDetails() {

    return (
        <Carousel className='slider_wrapper' data-testid="gallery_image">
            <Carousel.Item interval={4000}>
                <div className="slider_item">
                    <img className="gallery_image" src={image1} alt='grape_1' />
                    <img className="gallery_image" src={image2} alt='grape_2' />
                </div>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <div className="slider_item">
                    <img className="gallery_image" src={image3} alt='grape_3' />
                    <img className="gallery_image" src={image4} alt='grape_4' />
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default MediaGalleryDetails;
