import hotelLogo from '../../assets/hotelLogo.png'

function HotelLogo() {
    return (
        <div className="footer_title_logo_wrapper">
            <img src={hotelLogo} className="footer_title_logo" alt="hotel_logo" />
        </div>
    )
}

export default HotelLogo
