import manufacturerIcon from '../../assets/manufacturerIcon.jpg'
import { IAttribute } from '../../models/IOutputData'
import KeyValue from '../keyValue/KeyValue'
import LanguageSelector from '../languageSelector/LanguageSelector'

interface ComponentInfoProps {
    title: string,
    trustedAttributes: IAttribute[],
    language: string,
    setLanguage: (lang: string) => void
}

function GeneralDetails({ title, trustedAttributes, language, setLanguage }: ComponentInfoProps) {

    return (
        <div className='general_detail_wrapper' data-testid='general_detail_wrapper'>
            <div className="general_title_wraper">
                <div className="language_title_part">
                    <div className="language_wrapper">
                        <LanguageSelector
                            setSelectedLanguage={setLanguage}
                            selectedLanguage={language}
                        />
                    </div>
                    <div className="product_title">
                        {title}
                    </div>
                </div>
                <div className="manufacturer_icon_wrapper">
                    <img
                        id='manufacturer_icon'
                        className="manufacturer_icon"
                        src={manufacturerIcon} alt="manufacturer_icon" />
                </div>
            </div>
            <div className="general_info_wrapper">
                <KeyValue attributes={trustedAttributes} />
            </div>
        </div>
    )
}

export default GeneralDetails
