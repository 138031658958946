import poweredByConexo from '../../assets/pb.png'
import './ConexoLogo.scss'

function ConexoLogo() {
    return (
        <div className='conexo_logo_wrapper'>
            <a href="https://inevvo-solutions.com" target='_blank'>
                <img className='conexo_logo' src={poweredByConexo} alt="conexo_logo" />
            </a>
        </div>
    )
}

export default ConexoLogo
