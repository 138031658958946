import { ReactNode } from 'react'
import './Layout.scss'

interface LayoutProps {
    children_1: ReactNode
    children_2: ReactNode
}

function Layout({ children_1, children_2 }: LayoutProps) {
    return (
        <div className='layout_wrapper' data-testid='layout_wrapper'>
            <div className="layout_title">
                {children_1}
            </div>
            <div className="layout_content">
                {children_2}
            </div>
        </div>
    )
}

export default Layout
